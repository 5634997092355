import { LoadingComponent, SimpleModal, Textarea } from "@emsquared/otto_preact_form"
import { InfoWizard } from "../../shared"
import { Component, ComponentChild, Fragment } from "preact"
import restClient from "../../../backOffice/wui/restClient"
import { devLog } from "../../utils/log";
import appMan from "../appMan";
import { isAdmin } from "../utils";
import { LoadingCircle } from "../components/comps";

export class InfoWizardComp extends Component<{ wid: string }, { loading: boolean, wizard: InfoWizard }> {
    modal: SimpleModal;
    async loadData(): Promise<void> {
        devLog('load: id:', this.props.wid);
        let w = await restClient.InfoWizards.get(this.props.wid);
        this.setState({ wizard: w, loading: false });
    }
    startWizard() {
        devLog('Start Wiz')
    }
    shouldComponentUpdate(nextProps: any, nextState: Readonly<{ loading: boolean; wizard: InfoWizard; } & { loading: boolean; }>, nextContext: any): boolean {
        if (isAdmin(appMan.getUser())) {
            return true;
        }
        // super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    render(p: { wid: string; }, s: { loading: boolean; wizard: InfoWizard; }, c: any): ComponentChild {
        if(this.state.loading === undefined){
            this.loadData();
        }
        if (this.state.loading || this.state.loading === undefined) {
            return <LoadingCircle />   
        } else {
            return this.rrender(p, s);
        }

    }
    async sendToDirector(e) {
        e.preventDefault();
        console.log(this.state.wizard);
        await restClient.Tickets.sendInfoWizard(this.state.wizard, null, window.locals.infoWizard.breadCrumbs, null);
        appMan.showMessage('Sent. Thank you.')
        this.modal.close();


    }
    rrender(props?: { wid: string }, state?: Readonly<{ wizard: InfoWizard }>, context?: any): ComponentChild {
        
        devLog('info wiz render')
        if (isAdmin(appMan.getUser()) && !window.location.href.includes('help')) {
            if (this.base && this.base.parentElement) {
                console.log(this.base.parentElement.innerHTML);
                if (this.base.parentElement.innerHTML != this.state.wizard.name) {
                    this.base.parentElement.innerHTML = this.state.wizard.name;
                } else {
                    return this.state.wizard.name;
                }
                return
            } else {
                return this.state.wizard.name;
            }
        }else{
            if (this.base && this.base.parentElement) {
                devLog('base.parent', this.base.parentElement);
                if (this.base.parentElement.innerText) {
                    this.base.parentElement.innerText = '';
                }
            }
        }
        return <Fragment>
            {/* <button onClick={this.startWizard.bind(this)}>{this.state.wizard.name}</button> */}
            <SimpleModal ref={x => this.modal = x} label={this.state.wizard.name}>
                <form class="container" onSubmit={this.sendToDirector.bind(this)}>
                    <div class="row">
                        <p class="col-md-12">{this.state.wizard.desc}</p>
                    </div>
                    {this.state.wizard.questions.map((q, i) => <div class="row">

                        <div class="col-md-12">
                            <span>
                                {q.q}
                                <Textarea inputClass="midget" linkTo={this} name={`wizard.questions.${i}.a`} />
                            </span>
                        </div>
                        {/* </div> */}
                    </div>)}
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <button class="btn btn-success">Send to Director</button>

                        </div>
                        <div class="col-md-3">
                            <button onClick={e => {
                                this.modal.close();
                            }} type="button" class="btn btn-danger">Close</button>
                        </div>
                    </div>
                </form>

            </SimpleModal>
        </Fragment>
    }

}