export enum FutureLessonDisplayTypes{
	Cancellation = 1,
	Reschedule
}

export enum EndScheduleFrom{
	AddSchedule=1,
	Reschedule,
	EndSchedule
}
export enum WizardDirection {
	Forward,
	Back
}
export type LessonMarkActions = 'summerCancel' | 'moveAndTaught' | 'taught' | 'cancel' | 'schedulechange' | 'reschedule' | 'split' | 'unmark'
export enum DeltaAction {
	Nothing = 0,
	Add = 1,
	Remove,
	Edit,
	Delete
}
export type DeltaType<T> = Partial<T> & { action: DeltaAction }
export enum YesNo {
	No,
	Yes
}
export enum Numbers {
	One = 1,
	Two,
	Three,
	Four,
	Five,
	Six,
	Seven,
	Eight,
	Nine,
	Ten,
	Eleven,
	Twelve,

}
export enum LessonScheduleBilling {
	Monthly = 1,
	OneBill // All at once
}
export type Reminder = {
	note: string,
	contact?: Contact,
	done?: Date,
	ticket?: Ticket,
	scheduleSurvey?: ScheduleSurvey
} & ICalendarEvent
export type TCSApplication = {
	created: Date,
	user: Contact,
	student: Student
	approved?: Date,
	approvedBy?: Contact,
	declined?: Date,
	declinedNote?: String
} & Model
export type GeneralSearchVM = {
	from?: Date,
	to?: Date,
	school?: string

}
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export enum LessonLocation {
	"In-Person" = 1,
	Virtual,
	"In-Person & Virtual"

}
export enum Roles {
	Student,
	Family,
	Teacher,
	//after deploy bump these up. e.g. admin = 9000
	SchoolDirector,
	Admin,
	DirectorAssistant,
	"Shared Services"
}
export type LeadSource = NamedModel;
export enum ContactStatusType {
	Account = 1,
	Teacher,
	Student
}
export type ContactStatus = {
	name: string,
	type: ContactStatusType
}
export type User = Contact & {
	__t?: string,
	email: string,
	name: { first: string, last: string },
	addresses?: Address[],
	phones: Phone[],
	dob?: Date,
	fullName?: string; //Readonly
	username?: string,
	password?: string,
	roles?: Roles[],
	school?: School,
	notes?: Note[],
	active: boolean,
	leadSource?: LeadSource,
	leadSourceRef?: String, //url of referrer
	status?: ContactStatus
	area?: string //This is their default area.
	defaultSchool?: School,
	schools?: School[],
	agreementSigned?: Date;
	passwordResetToken?: string;
	taxId?: string;
	covidAcknowledgment?: { date: Date, ipAddress: string },
	lessonLocation: LessonLocation,
	timezone?: string
};
export enum Repeats {
	// Daily,
	Weekly = 1,
	BiMonthly,
	Monthly,
	OneTime,
	Many_One_Timers
}
export enum Days {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday
}
export interface Model {
	__original?:any,
	_id?: any
	id?: any,
	created?: Date,
	modified?: Date,
	deleted?: Date
}
export interface NamedModel extends Model {
	name: string
}
export enum UserPermissionObject {
	Account = 'accounts',
	Student = 'students',
	Schedule = 'schedules',
	Invoice = 'invoices',
	AccountCredit = 'accountCredits',
	Teacher = 'teachers',
	PayRecord = 'payRecords',
	Opportunitie = 'opportunities',
	StudentAccomplishment = 'studentAccomplishments',
	Todo = 'todos',
	Users = "users",
	MilestoneReminder = "milestoneReminders",
	Leads = "leads",
	Schools = "schools",
	LessonWizard = "lessonWizard",
	ReminderToDirector = "reminderToDirector",
	DirectorReminder = 'directorReminders'
}
export enum UserPermission {
	None,
	View = 1,
	Edit
}
export type DirectorAssistant = {
	user: User,
	students: UserPermission,
	schedules: UserPermission,
	invoices: UserPermission,
	accounts: UserPermission,
	accountCredits: UserPermission,
	teachers: UserPermission,
	payRecords: UserPermission,
	opportunities: UserPermission,
	studentAccomplishments: UserPermission,
	todos: UserPermission,
	milestoneReminders: UserPermission,
	users: UserPermission,
	leads: UserPermission,
	schools: UserPermission,
	lessonWizard: UserPermission,
	reminderToDirector: UserPermission,
	networkcontacts: UserPermission,
	directorReminders: UserPermission
	reports: {
		students: boolean,
		lessons: boolean,
		invoices: boolean,
		payRecords: boolean,
		pAndL: boolean,
		leads: boolean,
		retention: boolean,
		studentsByTeacher: boolean,
		accountBalances: boolean,
		credits: boolean
	},
	notifications: {
		// teacherAnsweredSurvey: boolean,
		teacherAnsweredCheckIn: boolean,
		teacherAnswered3CancelledInRow: boolean,
		teacherUnansweredSurvey: boolean,
		newScheduleEmails: boolean
	}

}
export type StudentAccomplishmentType = number;
export interface Subject extends NamedModel { }
export interface Schedule extends Model {
	city: any,
	duration: number, //minutes
	rate: number,
	tuition: number,
	schedule_info: string,
	startDate: Date,
	endDate: Date,
	created: Date,
	createdBy: Contact,
	welcomeSent?: Date,
	lessonLocation: LessonLocation,
	student: Student
}
export interface PhantomSchedule extends Schedule {

	instructor: Instructor,
	repeats: Repeats,
	repeatsOn: Days[],
	repeatsName: string,
	name: string,

	originalStartDate: Date,
	address: Address,
	day: Days
	//gonna be a fake one
	student: Student

	dirty?: boolean
}
export interface LessonSchedule extends Schedule {
	subjects: Subject[],
	student: Student,
	instructor: Instructor,
	repeats: Repeats,
	repeatsOn: Days[],
	//Virtual
	active?: boolean,
	//v
	subjectNames?: string[];
	//v
	dayNames?: string[];
	repeatsName: string


	originalStartDate: Date,

	createdFrom?: LessonSchedule,
	// tz: string
	dates?: Date[],
	billing?: LessonScheduleBilling,
	timezone?: string,

	audits: Audit[],
	editable:boolean
}
export type Audit = {
	changes: { name: string, newVal: string, oldVal: string }[],
	user: User,
	date: Date,
	type: string
}
export interface PotentialTime {
	from: String,
	to: String,
	repeats: Repeats,
	repeatsOn: Days[],
	note: string
	//Virtual
	dayNames?: string[]
	// idk
	days?
}
export interface PotentialStudentInfo {
	student: Student,

	potentialTimes: PotentialTime[],
	subjects: Subject[],
	duration: String,
	note: string,
	subjectNames: string[]
}
export interface Bid extends Model {
	requestedTimes: PotentialTime[],
	note: string
}
export interface PotentialSchedule extends Schedule {
	account: Account,
	emailSubject: string,

	potentialStudents: PotentialStudentInfo[];
	assignedTo?: Contact,
	assigned?: Date,
	assignedBy?: Contact,
	closed: Date,
	note: string
	bids?: Bid[];
	opportunities?: Opportunity;
}
export interface Note extends Model {
	text: string,
	user: Contact,
	date: Date,
	visibleToTeacher: boolean;
	edited?: Date;
}
export interface Address extends Model {
	address?: string,
	street: string,
	city: string,
	state: string,
	zip: string,
	oneLiner?: string,
	location: { type: 'Point', coordinates: number[] };
	forMail?: string;
	dirty?: boolean
}
export type PhoneType = number;
export interface Phone extends Model {
	number: string,
	type: PhoneType
}
export type CancellationDate = {
	from: Date,
	to: Date,
	reason: string | CancellationReason
}
export interface School extends Contact {
	desc: string, //School Name
	sharedServicesEnabled: boolean,
	directors: Contact[],
	directorAssistants: DirectorAssistant[],
	cancellationDates: CancellationDate[],
	rates: {
		minutes: number,
		rate: number,
		tuition: number
	}[]
}
export interface Contact extends Model {
	__t?: string,
	email: string,
	name: { first: string, last: string },
	address?: Address, // For view
	addresses?: Address[],
	phones: Phone[],
	dob?: Date,
	fullName?: string; //Readonly
	username?: string,
	password?: string,
	roles?: any[],
	school?: School,
	notes?: Note[],
	active: boolean,
	leadSource?: LeadSource,
	leadSourceRef?: String, //url of referrer
	// status?: IContactStatus
	area?: string //This is their default area.
	// defaultSchool?: models.ISchoolModel,
	// schools?: models.ISchoolModel[],
	agreementSigned?: Date;
	passwordResetToken?: string;
	taxId?: string,
	lessonLocation: LessonLocation,
	statusName?: string,
	timezone: string,
	utmSource: string,
	utmCampaign: string,
	bio: string,
	portraitPath: string,

	readonly fullPortraitPath?:string
}
export interface Account extends Contact {
	members: Student[];
	automaticPaymentCard: string,

	pauseCollectionForBillingCycle: boolean,
	//virtual
	firstBill?: Date,
	balance?: number
	currentSchedules?: LessonSchedule[],



}

export interface Instructor extends Contact {
	blurb: string,
	bio: string,
	education: string,
	employment: string,
	influences: string,
	awards: string,
	portraitPath: string,
	video: string,
	showOnSite: boolean,
	subjects: Subject[],
	participating: boolean,
	welcomeToken?: string,
	backgroundConsent?: { name: string, date: Date },
	optOutNewOpportunities: boolean,
	scheduleMeSettingsFilledOut: Date,
	todos: {
		showw9: boolean;
		showDD: boolean;
		showAgreement: boolean;
		showProfilePic: boolean;
		showBio: boolean;
		showBackgroundConsent: boolean;
		showScheduleMeSettings: boolean
		// showCovidacknowledge: boolean;
	}
	pendingBio?: string,
	pendingPortraitPath?: string,
	unansweredSurveys?: ScheduleSurvey[]
}
export interface Student extends Contact {
	subjects: Subject[],
	schedules: LessonSchedule[],
	// potentialSchedules?: models.IPotentialScheduleModel[];
	account: Account,
	ageNumber: string,
	potential: boolean,
	// familyCancellationInARow: number,
	badges?: { type: StudentAccomplishmentType, created: Date, requestedBy: Contact }[],
	tcsMemberships?: { created: Date, user: Contact }[]; // Treble Clef Society
	fiverFuckery?: Date,
	age: number;
	readonly subjectNames: string[]
};
export type StudentAccomplishment = {
	createdDate: Date,
	dateAchieved: Date,
	description: string,
	user: Contact,
	student: Student,
	type: StudentAccomplishmentType,
	attachments: StudentAccomplishmentAttachement[]
} & Model;
export type StudentAccomplishmentAttachement = {
	mimetype: string,
	originalname: string,
	filename: string,
	path?: string,
	processing?: boolean
} & Model;
export enum BankAccountType {
	Checking,
	Savings
}
export interface Opportunity extends Model {
	distance?: number;
	potentialSchedule: PotentialSchedule
	instructor: Instructor,
	date: Date,
	sendby: Contact,
	closed: Date,
	viewed: Date,
	declined: Date,
	requested: Date,
	bids: Bid[]
}
export enum LessonEventStatus {
	Scheduled,
	Taught,
	CanceledByInstructor,
	CanceledByStudent,
	RescheduledByStudent,
	RescheduledByInstructor,
	SummerCancellation,
	CanceledByLIYH,
	Holiday,
	MarkedByLIYH, // a hold 
	ProratedByLIYH,
	HolidayProrated,
	AutoMark, //A Hold
}
export type CancellationReason = any;
export interface PayStubCredit {
	instructor: Instructor,
	schedule: LessonSchedule,
	amount: number,
	submitted: Date,
	paystub?: PayStub, //ref to paystub applied to
	lesson: Lesson,
	void?: Date
}
export interface IPaystubLineItem {
	lesson?: Lesson,
	duration?: number,
	rate?: number,
	totalAmount: number,
	submitted: Date,
	description?: string,
	addedBy?: Contact, // Used for manually added items
	credit?: PayStubCredit
}
export interface PayStub extends Model {
	instructor: Instructor,
	city?: number,
	billingCycle: number,
	charges?: number,
	credits?: number,
	grandTotal: number,
	submitted: Date,
	processed: Date,
	items: IPaystubLineItem[]
	// status: PaystubStatus,
	number: Number,
	billingCycleRange?: { from: Date, to: Date },
	memo?: String,
	achFile?: String,
	YTD?: number
}
interface AccountTransactionItem {
	submitted: Date,
	totalAmount: number,
	description?: string,
	addedBy?: Contact;
	manual?: boolean//used for manually added items

}
export interface InvoiceItem extends AccountTransactionItem {
	student: Student,
	instructor?: Instructor,
	subjects?: Subject[],
	duration?: number,
	rate?: number,
	//V
	summary?: string, // quick desc of line item
	schedule: LessonSchedule,
	billingCycle?: number,
	lesson?: Lesson //For single lesson invocies
	// note?:string
}
export interface Transaction extends Model {
	__t: string,
	number: number,
	balance: number,
	grandTotal: number,
	billingCycle: number,
	account: Account

}
export interface Invoice extends Transaction {
	number: number,
	items: InvoiceItem[],
	stutus: number,
	statusName: string,
	balance: number,
	submitted: Date,
	billingCycle: number,


}
export type EventWrapper = {
	data: Lesson,
	className: string,
	title: string,
	start: Date

}
export interface ICalendarEvent extends Model {
	creator: Contact,
	name: string,
	startDate: Date,
	endDate?: Date,
	duration: number,
	rescheduledStartDate?: Date,
	/* 
	* Virtual
	*/
	start?: Date
	//V
	end?: Date
	//V
	created: Date,
	// modified: Date
	status?: number
}
export interface Lesson extends ICalendarEvent {
    unmarkable: boolean
	unmarked:boolean
	__t?: string,
	notes?: string,
	materials?: any[], //TODO: Check with Jay to see if we want to attach materials to event
	student: Student,
	instructor: Instructor,
	status?: LessonEventStatus,
	cancellationNote?: string,
	canceledOn?: Date,
	canceledBy?: Contact,//TODO(rocky): What do we need to tell who originally canceled 
	schedule?: LessonSchedule,
	subjects: Subject[],
	statusName?: string,
	// history:LessonEventHistory[]
	cancellationReason?: CancellationReason
	statusDate?: Date; //This is where we will pick them up for paystubs
	paystub?: PayStub, // when paid out
	invoice?: Invoice, // when paid,
	rate: number;
	tuition: number;
	splitFrom?: Lesson | string,
	splitInto?: Lesson[] | string[]
	audits: Audit[]


	// Lesson Wizard
	movedForTaught?: boolean;
}
export const W9_TaxClass = {
	Individual: {
		name: 'Individual/sole proprietor or single-member LLC',
		value: 1
	},
	C_Corporation: {
		name: 'C Corporation',
		value: 2
	},
	S_Corporation: {
		name: 'S Corporation',
		value: 3
	},
	Partnership: {
		name: 'Partnership',
		value: 4
	},
	Trust_Estate: {
		name: 'Trust/estate',
		value: 5
	}
}

export const w9TaxCodes = Object.keys(W9_TaxClass).map(x => W9_TaxClass[x]);
export type ScheduleMeAvailability = {

	days: Days[],
	from: Date,
	to: Date,
	note: string,
	comingFromOtherAddress: boolean
	address: Address
	outsideCommitments: PhantomSchedule[],
	haveOutsideCommitments: boolean;

	dirty?: boolean

}
export type InstaSchedulingInfo = {
	adminOverrideActive: boolean,
	active: boolean,
	teacher: Instructor,
	availability: ScheduleMeAvailability[],
	maxDistance: number,
	maxDistanceFromHome: number,
	timezoneOverride?: string,
	teacherNotes?: string,
	directorNotes?: string
} & Model;


export type InstaResults = {
	removed: boolean,
	removedReason?: string,
	teacher: Instructor,
	teacherInstaInfo: InstaSchedulingInfo,
	originalDayAvailability: { from: Date, to: Date },
	fromLocation: { type: 'Point', coordinates: number[] },
	toLocation: { type: 'Point', coordinates: number[] },
	availability: {
		minutesTravelFrom: number,
		minutesToTravelTo: number,
		day: Days
		from: Date,
		to: Date,
		schedulesThatDay: LessonSchedule[],
		duration: number // in minutes
		
	},
	// dayDic:any,
	distanceFromStart: number,
	distanceFromHome: number,
	distanceFromLastSchedule: number,
	distanceToNextSchedule: number,
	locationNextSchedule: { type: 'Point', coordinates: number[] },
	locationFromSchedule: { type: 'Point', coordinates: number[] },
	closestTo?: number
}


//Shared Services CS
export enum TicketSource {
	Manual,
	Phone,
	Email,
	Text,
	COG,
	AutoClose,
	"Com & Assign",
	Merge,
	InfoWizard,
	TeacherAutoClose,
	LateInvoiceSystem
}
export enum TicketMessageSentVia {
	Email = TicketSource.Email,
	Text = TicketSource.Text

}
export enum TicketChannel {
	NA,
	Billing
}
export type TicketDashboardNumbers = {
	active: number,
	myTickets: number,
	unresolved: number,
	overdue: number,
	dueToday: number,
	open: number,
	onhold: number,
	openByChannel: { channel: TicketChannel, number: number }[]
	resolvedLast30Days: number,
	last30BySchool: { school: School, number: number }[],
	averageReplyTimeLast30Days: number,
	teacherTicketGuess: { teacher: Instructor, count: number }[],
	ticketsClosedLast12Months: { _id: { year: number, month: number }, count: number }[],
	trends: { total: number, thisMonth: number, lastMonth: number, thisMonthLastYear: number }
	//Admin
	adminTicketDashboardNumbers?: AdminTicketDashboardNumbers

}
export type AdminTicketDashboardNumbers = {
	resolvedByChannelLast30days: { channel: TicketChannel, number: number }[]
	topArticles: { article: KnowledgeBaseArticle, count: number }[],
	ticketsByCTA: { [key: string]: number }
}
export type TicketDashboardVM = {
	numbers: TicketDashboardNumbers,
	myTickets: Ticket[],
	queue: Ticket[],
	assignedTickets: Ticket[],
	teacherTicketNumbers: {
		autoClose: number,
		infoWizard: number
	},
	teacherTopArticles: {
		article: KnowledgeBaseArticle,
		count: number
	}[],
	topTeachersTeacherTickets: {
		count: number,
		contact: Contact
	}[]
}
export type TicketAttachment = {
	orginalName: string,
	name: string,
	mimetype: string,
	size: number,
	// virtual
	path?: string
};
export type TicketNote = {
	_id?: any,
	body: string,
	user: Partial<User>,
	source: TicketSource,
	date: Date,
	pinned?: Date,
	sentToClient?: Date,
	sentVia?: TicketMessageSentVia,
	sentTo?: string[]
	edited: Date,
	editedByUser: Contact,
	messageId?: string,
	attachments: TicketAttachment[]
}
export type TicketLogEntry = {
	event: 'Added Note'
	| 'Status Change'
	| 'Assigned'
	| 'Created'
	| 'Unclaimed'
	| 'Claimed'
	| 'Email'
	| 'SMS'
	| 'Email Sent'
	| 'SMS Sent'
	| 'Merged'
	| 'VM'
	,
	date: Date,
	user: Partial<User>,
	val?: any,
	oldVal?: any
}
export enum TicketCreationCampaign {
	ParentCog = 1,
	TeacherCog,
	LateInvoiceSystem
}
export type Ticket = {
	from?: Partial<Contact> | Partial<Account> | Partial<Instructor>,
	school: School,
	channel: TicketChannel,
	created: Date,
	user: Partial<User>,
	assignedTo?: User,
	assignedDate?: Date,
	subject: string,
	body: string,
	status: TicketStatus,
	source: TicketSource,
	log: TicketLogEntry[],
	notes: TicketNote[]
	due: Date,
	number: number,
	lastStatusChange: Date,
	student?: Student,
	teacher?: Instructor,
	originalEmail?: IncomingEmail
	id?: any,
	_id: any,
	mergedFrom: Ticket[],
	attachments: TicketAttachment[],
	article: KnowledgeBaseArticle,
	campaign: TicketCreationCampaign

	//virtuals
	lastStatusChangeBy?: User
}
// & Model;
export enum TicketStatus {
	Open = 1,
	ReOpened,
	// Overdue,
	// DueToday,
	'On Hold',
	Closed,
	Escalated
}
export type QuickbooksCreditCard = {
	id: string,
	cardType: string,
	name: string,
	number: string,
	expMonth: string,
	expYear: string,
	created: string,
	updated: string,
	entityVersion: string,
	cvcVerification: any,
	entityId: string,
	entityType: string,
	zeroDollarVerification: any,
	default: boolean,
	isBusiness: boolean,
	isLevel3Eligible: boolean
}
export enum CreditCardType {
	VISA = 'VISA',
	MC = 'MC',
	AMEX = 'AMEX',
	DISC = 'DISC',
	DINERS = 'DINERS',
	JCB = 'JCB',
}
export type AccountTicketVM = {
	balance: number,
	invoices: Transaction[], // really transactions
	firstInvoiceBillingCycle: number,
	account: Account,
	savedCard: QuickbooksCreditCard[]
};
export type TeacherTicketVM = {
	//Don't know yet
	teacher: Instructor
};
//Knowledge Base
export enum KnowledgeBaseAccess {
	Internal = 1,
	External = 2
}
export enum KnowledgeBaseType {
	KB = 1,
	SOP,
	TREE
}
export type KnowledgeBaseCommunicationTemplate = {
	template: string,
	name: string
}
export type KnowledgeBaseArticle = {
	title: string,
	body: string,
	type: KnowledgeBaseType,
	access: KnowledgeBaseAccess,
	keywords: string[],
	emailTemplates: KnowledgeBaseCommunicationTemplate[],
	txtTemplates: KnowledgeBaseCommunicationTemplate[],
	slug: string,

	forRoles: Roles[]
	//Not Used Anymore
	emailTemplate: string,
	txtTemplate: string


} & Model;
export type mailOpts = {} & any;
export enum SharedServicesMessageType {
	Email = 1,
	Text
}
export type SharedServicesMessage = {
	type: SharedServicesMessageType,
	opts: mailOpts,
	created: Date,
	sent: Date,
	errs: any[]
	response: any,
	processing: boolean
}

export type IncomingEmail = {
	date: Date
	from: string,
	subject: string,
	body: string,
	seqNo: number,
	"message-id": string,
	ticketId: string,
	forwardedEmail: boolean
}

export type GOTOMessage = {
	id?: string,
	to: string[] | string,
	body: string
}
export type GOTOMessageRes = {
	ownerPhoneNumber: string,
	contactPhoneNumbers: string[],
	authorPhoneNumber: string,
	authorExternalUserKey: string,
	id: string,
	timestamp: string,
	direction: 'OUT' | 'In',
	deliveryStatuses: string[],
	processedAsShared: boolean,
	body: string,
	media: string[],
	labels: string[]
}
export type GOTOConverstationSummary = {
	"unreadMessagesCount": number
}
export type GOTOConVersation = {
	ownerPhoneNumber: '+14704652561',
	contactPhoneNumbers: string[],
	unreadMessagesCount: number,
	lastMessageId: '9f6028d5-c27a-4a6d-80ca-91a7dab5f4f1',
	lastMessageTimestamp: '2023-02-12T02:06:25.588962Z',
	lastReadMessageId: 'ebff3b43-b278-4b41-b99d-e15390e1bec1',
	lastReadMessageTimestamp: '2023-02-08T19:44:22.981522Z',
	folder: 'INBOX',
	lastMessage: GOTOMessage
};
export type GOTONotifcationChannel = {
	"channelId": "Webhook.1e143eab-6ae6-46ed-80db-512ee2a9cbb4",
	"channelNickname": "my-channel",
	"webhookChannelData": {}
}
export type GOTOUnReadConversationsRes = {
	sort: 'DESCENDINGLAST_MESSAGE_TIMESTAMP',
	items: GOTOConVersation[]
}
export type GOTOMessagesRes = {
	items: GOTOMessage[]
}
export type GOTOSMSWebHookBody = {
	"source": "messaging",
	"type": "message",
	"usage": "ALERT",
	"timestamp": "2022-12-01T19:48:42.392945Z",
	"ttl": 1209600,
	"content": {
		"ownerPhoneNumber": "+14704652561",
		"contactPhoneNumbers": ["+17406327370"],
		"authorPhoneNumber": string,
		"id": "78e23651-df10-435b-ab7c-99f9f527e30f",
		"timestamp": "2022-12-01T19:48:42.373957Z",
		"direction": "IN" | "OUT",
		"deliveryStatuses": [],
		"processedAsShared": false,
		"body": "Test wh",
		"media": {
			id: string,//'5468df47-a1f1-4ae6-ad9a-7912c3fc83c0',
			filename: string, //'IMG_1752.jpg',
			contentType: string, //'image/jpeg',
			size: number //200684
		}[],
		"labels": []
	},
	"bypassDoNotDisturb": false
}
export type GOTOVMWebHookBody = {
	"source": "VOICEMAIL",
	"type": "NEW_VOICEMAIL",
	"usage": "ALERT",
	"timestamp": "2020-11-10T17:59:07Z",
	"ttl": 1209600,
	"content":
	{
		"voicemailId": "djE6YzA4ODMzNWYtOTgxZS00MGM1LTliOTktOTZkZjA1M2Q0ZjYwL0NDVFJMX1ZPSUNFTUFJTC83N2U1OGFjOC1lODM4LTQxOWUtYTA3NC0xYmNiZjMzMjY0ZWEvNDE3ZTU1NjQtMmUzNy00ZTJiLWEwODQtNzg0OGQwMmIxNWU0",
		"voicemailboxId": "606d12ff-9482-4ff0-8971-95133ffc47d1",
		"organizationId": "be342206-96ac-482b-bd2d-5fb317e7daa1",
		"accountKey": "2930718022414574861",
		"extensionNumber": "1243",
		"calledNumber": "1234",
		"callerName": "John Doe",
		"callerNumber": "1003",
		"legId": "229e8710-409a-40fa-908c-de647f66298a",
		"durationMs": 6000,
		"timestamp": "2020-11-10T17:59:08Z",
		"status": "NEW",
		"folderCapacity": 99,
		"newMessageCount": 10,
		"readMessageCount": 25,
		"lastUpdatedTimestamp": "2020-11-10T19:59:08Z"
	}
}
export type GOTOGetVoicemailBoxesRes = {
	items: {
		voicemailboxId: 'bd191a09-cce7-4380-934f-033979507dde',
		organizationId: '79a21888-6001-4af1-8936-93431fe2ce29',
		extensionNumber: '1010',
		newMessageCount: 0,
		readMessageCount: 0
	}[];
}
export type CogTree = {
	branches: CogTreeBranch[],
	roles: Roles[],
	name: string,
} & Model;

export enum TreeBranchType {
	Continue = 1,
	KBAutoClose,
	CommunicateAndAssign,
	Link
}
export type CogTreeBranch = {
	cogTree: CogTree,
	// for auto close
	article?: KnowledgeBaseArticle,
	// for comm and Assign
	questionAbout?: string
	branches: CogTreeBranch[],
	name: string,
	type: TreeBranchType,
	roles: Roles[],
	href?: string
} & Model;


export type QuestionAndAnswer = {
	q: string,
	a: string
}
export type ScheduleSurveyQuestionAndAnswer = {
	type: ScheduleSurveyQuestionType,
	options: string[]
} & QuestionAndAnswer
export enum ScheduleSurveyType {

	"Three Lesson Follow Up" = 9999,
	"Three Cancelled Lessons In a Row",
	"Unmarked Lessons"

}
export enum TeacherNoficicationFor {
	"3 Days To Start" = 1,
	"Schedule Starting Tomorrow",
	"3 Month Follow Up",
	"6 Month Follow Up"
}
export enum ScheduleSurveyQuestionType {
	TextArea = 1,
	Select
}
export type ScheduleSurvey = {
	user: User,
	schedule: LessonSchedule,
	questionnaire: ScheduleSurveyQuestionAndAnswer[],
	created: Date,
	completed: Date,
	type: ScheduleSurveyType
	typeName?: string
	email: string,
	reviewed?: Date
} & Model;
export type ScheduleSurveyQuestion = {
	type: ScheduleSurveyType,
	sortOrder: number,
	question: string,
	questionType: ScheduleSurveyQuestionType,
	options: string[]
} & Model

export type TeacherDailyDigestData = {
	reminders: any[]
	lessons: Lesson[]
	unmarkedLessons: Lesson[],
	potentialStudents: Opportunity[],
	unansweredSurveys: ScheduleSurvey[],
	canceled3InARow: LessonSchedule[],
	scheduleFollowups: { 2: LessonSchedule[]; 1: LessonSchedule[]; 3: LessonSchedule[]; 4: LessonSchedule[]; 5: LessonSchedule[]; },
	unmarkedLessonsOlderThan3weeks: Lesson[]
}
export enum SendVia {
	Email = 1,
	Text
}
export type New_Notification = {
	contact: Contact,
	sendVia: SendVia,
	subject?: string,
	body: string,
	processing: boolean,
	scheduledFor: Date,
	sent?: Date,
	errs: any[] // TODO: Type this better,
	extId: string,
	cancelled?: Date,
	__t?: string
} & Model;

export enum AccountNew_NotificationType {
	OverdueBalance = 1,
	FailedAutoPay
}
export type AccountNew_Notification = New_Notification & {
	type: AccountNew_NotificationType
}
export type Agg_idCount = { _id: string, count: number };
export type TeacherReport = {
	numbersForSource: Agg_idCount[]
	numbersForCampaign: Agg_idCount[]
}
export type Question = {
	q: string

	//Not gonna save for now
	a?: string
	// More to come?
}
export type InfoWizard = {
	name: string,
	desc: string,
	questions: Question[]
} & Model;

export type ActivityForActivityStream = {
	action: string,
	what: New_Notification | Account | Schedule | Student | Audit | Contact | { _id: any, fullName: string } | Lesson,
	when: Date,
	who: User

}

export type DB2Data = {
	account: Account,
	agreementSigned: boolean,
	currentBalance: { balance: number, overdue: number },
	events: Lesson[],
	invoicesDue: Invoice[]
}

export type Task = Model & {
	created?: Date,
	scheduledFor: Date,
	ran?: Date,
	started?: Date,
	error?: any,
	notificationSent?: Date
}
export enum LateInvoiceTaskDay {
	Eleven = 11,
	Sixteen = 16,
	Eighteen = 18,
	Nineteen = 19,
	Twenty = 20,
	TwentyOne = 21,
	TwentyTwo = 22
}
export type LateInvoiceTask = Task & {
	whichDay: LateInvoiceTaskDay
}

export type LessonScheduleVM = LessonSchedule & {
	noteToAppend: string, days: string, startTime: string
};
export type LessonVM = Lesson & {
	originalStart:Date,
	willBeRemoved?:boolean
}
export type  ValidateRef = {
	validate:()=>boolean
}
