import { Account, ActivityForActivityStream, CogTree, CogTreeBranch, Contact, DirectorAssistant, InfoWizard, Instructor, KnowledgeBaseArticle, Opportunity, ScheduleSurveyQuestion, School, Ticket, TicketDashboardNumbers, TicketNote, User, UserPermissionObject } from "../../shared/shared";
import { devLog } from "../../shared/utils/log";
import { CDS, postJSON, toUrl, getJSON, postForm } from "../../shared/wui/fetch";

class SchoolsRC extends CDS {
	removeCancellationDate(id: any, cancellationDate) {
		return postJSON(this.endpoint + '/removeCancellationDate/', { schoolId: id, date_id: cancellationDate._id });
	}
	addNewCancellationDate(schoolId, cancellationDate: {}) {
		return postJSON<any>(this.endpoint + '/addCancellationDate/' + schoolId, cancellationDate);
	}
	changeReportPermissions(schoolId: string, ass: any) {
		return postJSON(this.endpoint + '/changeReportPermssions', { schoolId, ass: ass._id, reports: ass.reports });
	}
	changeNotificationPref(schoolId: string, ass: any) {
		return postJSON(this.endpoint + '/changeNotificationPreferences', { schoolId, ass: ass._id, notifications: ass.notifications });
	}
	removeAssistant(schoolId: string, _id: any) {
		return postJSON(this.endpoint + '/removeAssistant', { schoolId, permission_id: _id });
	}
	changePermission(schoolId: string, _id: any, obj: UserPermissionObject, newVal: any) {
		return postJSON(this.endpoint + '/changePermssion', { schoolId, permission_id: _id, obj, newVal });
	}
	loadDirectorAssistants(schoolId) {
		return getJSON<DirectorAssistant[]>(this.endpoint + `/loadAssistants/${schoolId}`);
	}
	endpoint = toUrl('api/schools');
	list() {
		return getJSON<School[]>(this.endpoint)
	}
	get(id: string) {
		return getJSON<School>(this.endpoint + `/${id}`);
	}
	addAssistant(schoolId, assistant) {
		return postJSON(this.endpoint + '/addAssistant/' + schoolId, assistant);
	}
}
class AccountRC extends CDS {
	get(id: string) {
		return getJSON<Account>(this.endpoint + `/${id}`);
	}
	endpoint = toUrl('api/accounts');
	search(data) {
		return postJSON<Account[]>(this.endpoint + '/search', data);
	}
	getTranactions(id){
		return getJSON<any[]>(this.endpoint + `/trans/${id}`);
	}
	getActivityStream(id){
		return getJSON<ActivityForActivityStream[]>(this.endpoint + `/activityStream/${id}`);
	}
}
class ReportsRC extends CDS {
	lessonStatus(){
		return postJSON<any[]>(this.endpoint + '/lessonStatus', {});
	}
	endpoint = toUrl('api/reports');
	
}
class CalendarDS extends CDS {
	endpoint = toUrl('api/calendar')
	getEmByAccount(accountId, fromDate, toDate) {
		return getJSON(`${this.endpoint}/forAccount/${accountId}?start=${fromDate.toISO()}&end=${toDate.toISO()}`)
	}
	getEmByTeacher(teacherId, fromDate, toDate) {
		return getJSON(`${this.endpoint}/forTeacher/${teacherId}?start=${fromDate.toISO()}&end=${toDate.toISO()}`)
	}
}
class SearchRC {
	endpoint = toUrl('api/search')
	search(q) {
		return getJSON<Contact[]>(this.endpoint + `?q=${q}`);
	}
}
class LessonRC {
	endpoint = toUrl('api/lessons')
	loadAudits(id) {
		return getJSON<any>(this.endpoint + '/loadAudits/' + id)
	}
}
class TeacherRC extends CDS {
    listByUTMSource(filterCrit: {}, source: any) {
        return postJSON<Instructor[]>(this.endpoint + '/filterByUTM', { ...filterCrit, source });
    }
	listByUTMCampaign(filterCrit: {}, campaign: any) {
        return postJSON<Instructor[]>(this.endpoint + '/filterByUTM', { ...filterCrit, campaign });
    }
	getOpps(teacherId: string) {
		return postJSON<Opportunity[]>(this.endpoint + '/opps', { teacherId });
	}
	search(searchCrit: any) {
		return postJSON<Instructor[]>(this.endpoint + '/search', searchCrit);
	}
	endpoint: string = toUrl('api/teachers');
	list() {
		return getJSON<Instructor[]>(this.endpoint);
	}
	get(id) {
		return getJSON<Instructor>(this.endpoint + '/' + id);
	}
	listAllForAccount(id) {
		return getJSON<Instructor[]>(this.endpoint + '/fa/' + id);
	}

}
class UserRC extends CDS {
	getAllDirectorAssistants() {
		return getJSON<User[]>(this.endpoint + '/allDirectorAssistants');
	}
	endpoint: string = toUrl('api/users');
}
class TicketRC extends CDS {
	sendAndCreateAndClose(articleId: string, branch: CogTreeBranch) {
		return postJSON<Ticket>(this.endpoint + '/sendAndCreateAndClose', { article: articleId, branch });
	}
	sendAdditionalQuestion(additionalQuestion, branch: CogTreeBranch, breadCrumbs, ticketId) {
		return postJSON<Ticket>(this.endpoint + '/addQToTicket', { additionalQuestion, ticketId, branch, breadCrumbs });
	}
	sendInfoWizard(wizard:InfoWizard, branch: CogTreeBranch, breadCrumbs, ticketId) {
		return postJSON<Ticket>(this.endpoint + '/sendInfoWizard', { wizard, ticketId, branch, breadCrumbs });
	}
	listForMe(): PromiseLike<Ticket[]> {
		return getJSON<Ticket[]>(`${window.locals.bomp}api/tickets/listForMe`);
	}
	addNote(_id: any, newNote: TicketNote) {
		return postJSON<Ticket>(this.endpoint + '/' + _id + '/addNote', newNote);
	}
	filter(filterCrit: Partial<Ticket>) {
		return postJSON<Ticket[]>(window.locals.csmp + 'api/tickets/filter', filterCrit);
	}
	getDashboardNumber() {
		return getJSON<TicketDashboardNumbers>(`${window.locals.bomp}api/tickets` + '/dashboardNumbers');
	}
	save(ticket: Ticket) {
		return postJSON<Ticket>(this.endpoint, ticket);
	}
	get(id) {
		return getJSON<Ticket>(this.endpoint + '/' + id);
	}
	forAccount(id) {
		return getJSON<Ticket[]>(this.endpoint + '/forAccount/' + id);
	}
	forTeacher(id) {
		return getJSON<Ticket[]>(this.endpoint + '/forTeacher/' + id);
	}
	getAllTicketUsers() {
		return getJSON<User[]>(this.endpoint + '/getAllAssignableUsers/');
	}
	editNote(id: any, note: any) {
		return postJSON<any>(this.endpoint + `/${id}/editNote`, note)
	}
	unclaim(ticket: Ticket) {
		return getJSON<Ticket>(this.endpoint + `/unclaim/${ticket.id}`);
	}
	claim(ticket: Ticket) {
		return getJSON<Ticket>(this.endpoint + `/claim/${ticket.id}`);
	}
	endpoint: string = toUrl('api/tickets');
}
class KnowledgeBaseArticleRC extends CDS {
	filter(data) {
		devLog('filter kb articles', data)
		return postJSON<any>(this.endpoint + '/filter/', { q: data.q, forRole:data.forRole });
	}
	endpoint: string = toUrl('api/kbarticles');
	list() {
		return getJSON<KnowledgeBaseArticle[]>(this.endpoint);
	}
	get(id) {
		return getJSON<KnowledgeBaseArticle>(this.endpoint + '/' + id);
	}
	save(data) {
		return postJSON<KnowledgeBaseArticle>(this.endpoint, data);
	}
	delete(id) {
		return postJSON<any>(this.endpoint + '/delete/' + id, {});
	}
}
class CogTreeRC extends CDS {
	save(tree: CogTree) {
		return postJSON<CogTree>(this.endpoint, tree);
	}
	get(id) {
		return getJSON<CogTree>(this.endpoint + '/' + id);
	}
	endpoint = toUrl('api/cogTrees');
	list() {
		return getJSON<CogTree[]>(this.endpoint);
	}
}
class CogTreeBranchRC extends CDS {
	delete(id: any) {
		return postJSON<CogTreeBranch>(this.endpoint + '/delete', { id });
	}
	endpoint = toUrl('api/cogTreeBranches');
	list() {
		return getJSON<CogTreeBranch[]>(this.endpoint);
	}
	save(branch: CogTreeBranch) {
		return postJSON<CogTreeBranch>(this.endpoint, branch);
	}
}
class ScheduleSurveyQuestionRC extends CDS {
	get(id) {
		return getJSON<ScheduleSurveyQuestion>(this.endpoint + '/' + id);
	}
	delete(id: any) {
		return postJSON<ScheduleSurveyQuestion>(this.endpoint + `/delete/${id}`, { id });
	}
	endpoint = toUrl('api/scheduleSurveyQuestions');
	list() {
		return getJSON<ScheduleSurveyQuestion[]>(this.endpoint);
	}
	save(q: ScheduleSurveyQuestion) {
		return postJSON<ScheduleSurveyQuestion>(this.endpoint, q);
	}
}
class InfoWizardsRC extends CDS {
	get(id) {
		return getJSON<InfoWizard>(this.endpoint + '/' + id);
	}
	delete(id: any) {
		return postJSON<InfoWizard>(this.endpoint + `/delete/${id}`, { id });
	}
	endpoint = toUrl('api/infoWizards');
	list() {
		return getJSON<InfoWizard[]>(this.endpoint);
	}
	save(q: InfoWizard) {
		return postJSON<InfoWizard>(this.endpoint, q);
	}
}
class FetchTheData {
    get InfoWizards(){
		return new InfoWizardsRC();
	}

	get Schools() {
		return new SchoolsRC();
	}
	get Accounts() {
		return new AccountRC();
	}
	get Calendar() {
		return new CalendarDS();
	}
	get Search() {
		return new SearchRC();
	}
	get Lessons() {
		return new LessonRC();
	}
	get Teachers() {
		return new TeacherRC();
	}
	get Users() {
		return new UserRC();
	}
	get KnowledgeBaseArticles() {
		return new KnowledgeBaseArticleRC();
	}
	get Tickets() {
		return new TicketRC();
	}
	get CogTrees() {
		return new CogTreeRC();
	}
	get CogTreeBranches() {
		return new CogTreeBranchRC();
	}
	get ScheduleSurveyQuestions(){
		return new ScheduleSurveyQuestionRC();
	}
	get Reports(){
		return new ReportsRC();
	}

}
export default new FetchTheData();